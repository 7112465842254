import { createApp } from "vue";
import App from "./App.vue";
import router from "../src/router";
import { store } from "./store";
import "vue2-animate/dist/vue2-animate.min.css";
import ArgonDashboard from "./plugins/argon-dashboard";
import io from "socket.io-client";
import Toast from "vue-toastification";
import SocketIOExt from "vue-socket.io-extended";
import "vue-toastification/dist/index.css";
const options = { containerClassName: "ct-notification" };
import { env } from "@/config/index";
import ElementPlus from "element-plus";
// import JitsiMeeting from "@jitsi/vue-sdk";

// import "element-plus/dist/index.css";

//import "./assets/tailwind.css";
export const socket = io(env.SocketServer, {
	path: env.SocketPath,
	autoConnect: false,
});
createApp(App)
	.use(router)
	.use(SocketIOExt, socket, {
		store,
		actionPrefix: "",
		mutationPrefix: "SOCKET_",
	})
	.use(store)
	.use(Toast, options)
	.use(ElementPlus)
	.use(ArgonDashboard)
	// .use(JitsiMeeting)
	.mount("#app");
