import * as yup from "yup";

export const schemaStartMeet = () =>
	yup.object().shape({
		phone: yup
			.string()
			.trim()
			.matches(
				/^(\d{5,})$/,
				"El número solo puede contener digítos (al menos 5)!"
			)
			.required("Es requerido el número telefónico!"),

		datetime: yup
			.string()
			.trim()
			.required("Es requerido el campo de fecha y hora!")
			.test(
				"isValidDate",
				"La fecha y hora deben ser mayores a la actual!",
				value => {
					if (typeof value !== "string" || !value.trim()) {
						return false;
					}
					const date = new Date(value);
					return !isNaN(date.getTime()) && date > new Date();
				}
			),
	});
